<template>
  <footer>
    <div class="d-flex footer-menu">
        <ul class="list-inline mx-auto">
            <li class="list-inline-item">
                <router-link :to="{name : 'Page_72'}">
                    {{footerContents.menuItem1}}
                </router-link>
                
            </li>
            <li class="list-inline-item">
                <router-link :to="{name : 'Page_73'}">
                    {{footerContents.menuItem2}}
                </router-link>
            </li>
        </ul>
    </div>
    <Partners :partnerContents="footerContents"></Partners>
</footer>
</template>

<script>
import Partners from '../components/Partners.vue'

export default{
  name:'Footer',
  props:['globalData'],
  components:{
    Partners
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][69]
    const footerContents = contents.footer
    return{
      footerContents
    }
  }
}
</script>

<style scoped>
/*--------------------
        FOOTER
---------------------*/
footer .footer-menu{background-color:#eee;height:70px;padding:26px 0;}
footer a{font-size:14px; color:#313d6b;text-decoration: none;margin-right: 1rem; text-transform:uppercase;font-family: 'Proxima Nova Medium', sans-serif; opacity: 80%;}
footer .footer-logo{margin:0 0 87px 0;max-width:200px;}
footer .container{padding:60px 0 24px 0;text-transform:uppercase; color: rgba(49,61,107, .8);}
footer .partners a{display:block;height:67px;}
footer .partner-logo{position:absolute;margin:auto;top:0;bottom:0;left:0;max-width:100%;right:0;}
footer h3{margin:0 0 30px 0;text-align:left;font-weight:600;font-size:18px;line-height:18px;}

@media (max-width: 1030px) {
  #footer .top .logo{float:none;}
  #footer ul.footerMenu{float:none;padding:25px 0 0 0;}

}
@media (max-width: 768px){
  #footer .footer-logo{max-width:100%;}
}
</style>