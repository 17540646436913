<template>
   <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <img src="../assets/bg/graphisoft_itech_logo.svg" class="footer-logo" />
            </div>
        </div>
        <div class="row partners">
            <div class="col-12">
                <h3>Partnerek</h3>
            </div>
            <div class="col-sm">
                <router-link :to="{name : 'Page_77'}" class="position-relative">
                    <img class="partner-logo" src="../assets/partners/prohardver.png" />
                </router-link>
            </div>
            <div class="col-sm">
                <router-link :to="{name : 'Page_78'}" class="position-relative">
                   <img class="partner-logo" src="../assets/partners/mobilarena.png" />
                </router-link>
            </div>
            <div class="col-sm">
                <router-link :to="{name : 'Page_79'}" class="position-relative">
                   <img class="partner-logo" src="../assets/partners/itcafe.png" />
                </router-link>
            </div>
            <div class="col-sm">
                <router-link :to="{name : 'Page_80'}" class="position-relative">
                   <img class="partner-logo" src="../assets/partners/infha.png" />
                </router-link>
            </div>
            <div class="col-sm">
                <router-link :to="{name : 'Page_81'}" class="position-relative">
                   <img class="partner-logo" src="../assets/partners/svk.png" />
                </router-link>
            </div>
            <div class="col-sm">
                <router-link :to="{name : 'Page_83'}" class="position-relative">
                   <img class="partner-logo" src="../assets/partners/szte.png" />
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center small my-5">
                {{partnerContents.policy.replace('{nowYear}',new Date().getFullYear())}}
            </div>
        </div>
    </div>
</template>

<script>

export default{
  name:'Partners',
  props:['partnerContents']
}
</script>

<style scoped>
footer .footer-menu{background-color:#eee;height:70px;padding:26px 0;}
footer a{font-size:14px; color:#313d6b;text-decoration: none;margin-right: 1rem; text-transform:uppercase;font-family: 'Proxima Nova Medium', sans-serif; opacity: 80%;}
footer .footer-logo{margin:0 0 87px 0;max-width:200px;}
footer .container{padding:60px 0 24px 0;text-transform:uppercase; color: rgba(49,61,107, .8);}
footer .partners a{display:block;height:67px;}
footer .partner-logo{position:absolute;margin:auto;top:0;bottom:0;left:0;max-width:100%;right:0;}
footer h3{margin:0 0 30px 0;text-align:left;font-weight:600;font-size:18px;line-height:18px;}
</style>